<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      agents: [],
      transactions: [],
      accountingPeriods: [],
      accountFilter: {
        accounting_period_id: null,
        from: "",
        to: "",
      },
      selectedAgent: "",
      search: "",
      agent: {},
      totalCredit: 0,
      totalDebit: 0,
      index: 1,
      selectedId: null,
    };
  },
  methods: {
    getAgents() {
      this.http.post("agents/search", { search: this.search }).then((res) => {
        if (res.status) {
          this.agents = res.data.map((agent) => {
            return this.$i18n.locale == "ar"
              ? `${agent?.id}-${agent?.name}`
              : `${agent?.id}-${agent?.name_en}`;
          });
        }
      });
    },
    getAgentReport() {
      console.log({ accountFilter: this.accountFilter });
      this.http
        .post("agents/report", {
          id: this.selectedId,
          accountFilter: this.accountFilter,
        })
        .then((res) => {
          if (res.status) {
            this.transactions = res.data;
            this.totalCredit = 0;
            this.totalDebit = 0;
            this.transactions.forEach((transaction) => {
              if (transaction.type == "credit") {
                this.totalCredit += parseFloat(transaction?.value);
              } else {
                this.totalDebit += parseFloat(transaction?.value);
              }
            });
          }
        });
    },
    selecteAgent() {
      let agentIndex = this.agents.indexOf(this.selectedAgent);
      this.index =
        "CUS-" +
        (agentIndex < 10
          ? "000" + agentIndex
          : agentIndex < 100
          ? "00" + agentIndex
          : agentIndex < 1000
          ? "0" + agentIndex
          : agentIndex);
      console.log(this.customer);
      this.selectedId = this.selectedAgent.split("-")[0];
    },
    printReport() {
      window.print();
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
    // getAgents() {
    //   this.http.get("agents").then((res) => {
    //     if (res.status) {
    //       this.agents = res.data;
    //     }
    //   });
    // },
    // getAgentReport() {
    //   let agentIndex = this.agents.indexOf(this.agent);
    //   this.index =
    //     "SUP-" +
    //     (agentIndex < 10
    //       ? "000" + agentIndex
    //       : agentIndex < 100
    //       ? "00" + agentIndex
    //       : agentIndex < 1000
    //       ? "0" + agentIndex
    //       : agentIndex);
    //   console.log(this.agent);
    //   let data = this.agent;
    //   this.http.post("agents/report", data).then((res) => {
    //     if (res.status) {
    //       this.transactions = res.data;
    //       this.totalCredit = 0;
    //       this.totalDebit = 0;
    //       this.transactions.forEach((transaction) => {
    //         if (transaction.type == "credit") {
    //           this.totalCredit += parseFloat(transaction?.value);
    //         } else {
    //           this.totalDebit += parseFloat(transaction?.value);
    //         }
    //       });
    //     }
    //   });
    // },
  },
  computed: {
    filteredAgents() {
      return this.agents.filter((agent) => {
        return agent;
      });
    },
  },
  created() {
    this.getAccountingPeriods();
  },
};
</script>

<template>
  <Layout class="no-print">
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('agents.report')"
    />

    <div class="row">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="getAgentReport()">
            <div class="p-3" style="background-color: rgb(246, 247, 248)">
              <div class="row">
                <div class="col-auto d-flex" style="align-items: flex-end">
                  <div>
                    <label for="" class="d-block">Select Supplier</label>
                    <AutoComplete
                      @keyup="getAgents()"
                      v-model="selectedAgent"
                      :suggestions="filteredAgents"
                      @complete="search = $event.query"
                      style="color: #333 !important"
                    />
                  </div>
                  <div>
                    <label for="" class="d-block"></label>
                    <button
                      type="button"
                      style="margin-inline-start: 4px; height: 40px"
                      @click="selecteAgent"
                      class="btn btn-primary"
                    >
                      {{ $t("popups.select") }}
                    </button>
                  </div>
                </div>
                <!-- <div class="col-3">
                
              </div> -->
                <div class="col d-flex" style="height: 40px">
                  <div class="d-inline">
                    <label for="" class="d-block">Accounting Period</label>

                    <select
                      v-model="accountFilter.accounting_period_id"
                      class="form-select"
                      :disabled="selectedId == null"
                    >
                      <option
                        :value="period.id"
                        v-for="period in accountingPeriods"
                        :key="period"
                      >
                        {{
                          period?.start_date +
                          ` ${this.$i18n.locale == "ar" ? "الى" : "To"} ` +
                          period?.end_date
                        }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="d-inline"
                    style="margin-inline-start: 8px; margin-inline-end: 8px"
                  >
                    <label for="" class="d-block">From</label>

                    <input
                      v-model="accountFilter.from"
                      :disabled="selectedId == null"
                      type="date"
                      class="form-control"
                    />
                  </div>
                  <div class="d-inline">
                    <label for="" class="d-block">To</label>

                    <input
                      v-model="accountFilter.to"
                      :disabled="selectedId == null"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-auto d-flex" style="align-items: flex-end">
                  <button
                    :disabled="selectedId == null"
                    style="height: 40px"
                    class="btn btn-success"
                  >
                    Genrate
                  </button>
                </div>
              </div>
            </div>
          </form>

          <div v-if="transactions.length > 0">
            <br />
            <button class="btn btn-success float-end" @click="printReport()">
              <i class="bx bx-printer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" v-if="transactions.length > 0">
      <div class="card card-body">
        <ReportsHeader
          :reportName="$t('reports.suppliers_statement')"
          :accountNo="index + 1"
          :accountName="
            $i18n.locale == 'ar'
              ? transactions[0]?.name
              : transactions[0]?.name_en
          "
        />

        <div class="table-responsive">
          <table
            class="table table-centered table-nowrap table-striped table-hover align-middle"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
          >
            <thead>
              <tr
                class="text-light"
                style="background-color: #2a3042 !important"
              >
                <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
                <th scope="col">{{ $t("customers_report.date") }}</th>
                <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
                <th scope="col">{{ $t("customers_report.descr") }}</th>
                <th scope="col">{{ $t("customers_report.debit") }}</th>
                <th scope="col">{{ $t("customers_report.credit") }}</th>
                <th scope="col">{{ $t("customers_report.balance") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=""
                v-for="transaction in transactions"
                :key="transaction"
              >
                <!-- <td>{{ transaction.code }}</td> -->
                <!-- <td>{{ transaction.name }}</td> -->
                <td>{{ transaction?.created.split("T")[0] }}</td>
                <td>
                  {{
                    $i18n.locale == "ar"
                      ? transaction?.descr
                      : transaction?.descr_en
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "debit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td>
                  {{
                    transaction?.type == "credit"
                      ? parseFloat(transaction?.value).toLocaleString()
                      : ""
                  }}
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td class="total-style">
                  {{ parseFloat(totalDebit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{ parseFloat(totalCredit).toLocaleString() }}
                </td>
                <td class="total-style">
                  {{
                    (
                      parseFloat(totalCredit) - parseFloat(totalDebit)
                    ).toLocaleString()
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <th>{{ $t("reports.report_date") }} :</th>
              <th>
                {{
                  `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
                }}
              </th>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </Layout>

  <div class="row mt-4 print" v-if="transactions.length > 0">
    <div class="card card-body">
      <ReportsHeader
        :reportName="$t('reports.suppliers_statement')"
        :accountNo="index + 1"
        :accountName="
          $i18n.locale == 'ar'
            ? transactions[0]?.name
            : transactions[0]?.name_en
        "
      />

      <div class="table-responsive">
        <table
          class="table table-centered table-nowrap table-striped table-hover align-middle"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr class="text-light" style="background-color: #2a3042 !important">
              <!-- <th scope="col">{{ $t("general_ledger.account_id") }}</th> -->
              <th scope="col">{{ $t("customers_report.date") }}</th>
              <!-- <th scope="col">{{ $t("customers_report.particular") }}</th> -->
              <th scope="col">{{ $t("customers_report.descr") }}</th>
              <th scope="col">{{ $t("customers_report.debit") }}</th>
              <th scope="col">{{ $t("customers_report.credit") }}</th>
              <th scope="col">{{ $t("customers_report.balance") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" v-for="transaction in transactions" :key="transaction">
              <!-- <td>{{ transaction.code }}</td> -->
              <!-- <td>{{ transaction.name }}</td> -->
              <td>{{ transaction?.created.split("T")[0] }}</td>
              <td>
                {{
                  $i18n.locale == "ar"
                    ? transaction?.descr
                    : transaction?.descr_en
                }}
              </td>
              <td>
                {{
                  transaction?.type == "debit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td>
                {{
                  transaction?.type == "credit"
                    ? parseFloat(transaction?.value).toLocaleString()
                    : ""
                }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td class="total-style">
                {{ parseFloat(totalDebit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{ parseFloat(totalCredit).toLocaleString() }}
              </td>
              <td class="total-style">
                {{
                  (
                    parseFloat(totalCredit) - parseFloat(totalDebit)
                  ).toLocaleString()
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <th>{{ $t("reports.report_date") }} :</th>
            <th>
              {{
                `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getDay()},
                 ${new Date().getHours()}:${new Date().getMinutes()}`
              }}
            </th>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
.print {
  display: none;
}
@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
}
</style>
